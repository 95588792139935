import React from 'react';
import styled from 'styled-components';
import { formatCurrency } from '../../../utils';

export const Container = styled.div`
  display: none;
`;

export const Page = styled.div`
  display: block;
  background-color: #ffffff;
  margin: 50px;
  img {
    max-width: 200px;
  }
  h1 {
    font-size: 18px;
  }
  h2 {
    font-size: 16px;
  }
  table {
    width: 100%;
    height: auto;
    border: 1px solid #c1c1c1;
    padding: 0px;
    margin: 20px 0px;
  }
  tbody {
    width: 100%;
    height: auto;
    padding: 0px;
    margin: 0px;
  }
  tr {
    padding: 0px;
    margin: 0px;
    td: first-child {
      font-weight: bold;
    }
  }
  td {
    font-size: 14px;
    padding: 10px;
    margin: 0px;
    border: 1px solid #c1c1c1;
  }
`;

const TransactionInvoice = ({ invoice }) => {
  const {
    id,
    appLogo,
    appName,
    accountsName,
    createdOn,
    serviceName,
    operatorName,
    rechargeNumber,
    apiOperatorsId,
    amount,
    transactionsStatus,
    serviceCharge,
  } = invoice;
  return (
    <Container id={`existing-invoice-container-${id}`}>
      <Page>
        <img
          src={appLogo}
          alt={appName}
          style={{ maxWidth: '200px', maxHeight: '200px' }}
        />
        <h1>{appName}</h1>
        <h2>{accountsName}</h2>
        <table>
          <tbody>
            <tr>
              <td>Transaction Time</td>
              <td>{createdOn}</td>
            </tr>
            <tr>
              <td>Service</td>
              <td>{serviceName}</td>
            </tr>
            <tr>
              <td>Operator</td>
              <td>{operatorName}</td>
            </tr>
            <tr>
              <td>Number</td>
              <td>{rechargeNumber}</td>
            </tr>
            <tr>
              <td>Transaction Id</td>
              <td>{id}</td>
            </tr>
            <tr>
              <td>Operator Id</td>
              <td>{apiOperatorsId}</td>
            </tr>
            <tr>
              <td>Amount</td>
              <td>{formatCurrency(amount)}</td>
            </tr>
            {serviceCharge && (
              <>
                <tr>
                  <td>Service Charge</td>
                  <td>{formatCurrency(serviceCharge)}</td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td>
                    {formatCurrency(
                      parseFloat(amount) + parseFloat(serviceCharge)
                    )}
                  </td>
                </tr>
              </>
            )}
            <tr>
              <td>Status</td>
              <td>{transactionsStatus}</td>
            </tr>
          </tbody>
        </table>
      </Page>
    </Container>
  );
};

export default TransactionInvoice;
