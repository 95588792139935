import React, { useState, useEffect, useCallback, memo } from 'react';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import NoData from '../../../components/NoData/NoData';
import { MessageBox } from '../../../components/FormElements';
import { TableBuilder } from '../../../components/TableElements';
import API from '../../../api';
import {
  API_RESPONSE_TYPES,
  MODULES,
  PERMISSION_TYPES,
} from '../../../constants';
import { authData, formatCurrency } from '../../../utils';

const LiveRechargeTransactionsTable = (props) => {
  const { accountsPermissions } = authData.get();
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [transactions, setTransactions] = useState([]);

  const canViewApiProvider =
    accountsPermissions[MODULES.API_PROVIDERS] &&
    accountsPermissions[MODULES.API_PROVIDERS].includes(PERMISSION_TYPES.READ)
      ? true
      : false;

  const readData = useCallback((showLoading = false) => {
    showLoading && setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/transactions/live-transactions`)
      .then((response) => {
        const { status, data, message } = response.data;
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setTransactions(data);
        } else {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(message);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
        setTimeout(() => readData(false), 15000);
      });
  }, []);

  useEffect(() => {
    if (props?.appData?.liveScreen === 'Y') {
      readData(true);
    } else {
      setResponseStatus(API_RESPONSE_TYPES.FAILURE);
      setResponseMessage(
        'This feature is enabled on premium server packages ,please contact admin'
      );
    }
  }, [readData, props?.appData?.liveScreen]);

  return (
    <>
      <LoadingSpinner
        isLoading={
          responseStatus === '' && transactions?.length === 0 && isLoading
        }
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          transactions?.length === 0
        }
        message={`No transactions found`}
      />
      <MessageBox status={responseStatus} message={responseMessage} />
      {transactions?.length > 0 && (
        <TableBuilder
          isLoading={transactions?.length !== 0 && isLoading}
          tableHeadings={
            canViewApiProvider
              ? [
                  {
                    title: 'Operator',
                    dataSelector: 'operatorName',
                    dataType: 'string',
                    canSort: false,
                  },
                  {
                    title: 'Accounts Name',
                    dataSelector: 'accountsName',
                    dataType: 'string',
                    canSort: false,
                  },
                  {
                    title: 'Number',
                    dataSelector: 'rechargeNumber',
                    dataType: 'string',
                    canSort: false,
                  },
                  {
                    title: 'Amount',
                    dataSelector: 'amount',
                    dataType: 'number',
                    align: 'center',
                    CellRenderer: (value) => formatCurrency(value),
                    canSort: false,
                  },
                  {
                    title: 'API',
                    dataSelector: 'apiProvidersName',
                    dataType: 'string',
                    canSort: false,
                  },
                  {
                    title: 'Operators ID',
                    dataSelector: 'apiOperatorsId',
                    dataType: 'string',
                    align: 'center',
                    canSort: false,
                    CellRenderer: (value) => (value ? value : 'N/A'),
                  },
                  {
                    title: 'Processed On',
                    dataSelector: 'createdOn',
                    dataType: 'string',
                    align: 'center',
                    canSort: false,
                    CellRenderer: (value) => (value ? value : 'N/A'),
                  },
                ]
              : [
                  {
                    title: 'Operator',
                    dataSelector: 'operatorName',
                    dataType: 'string',
                    canSort: false,
                  },
                  {
                    title: 'Accounts Name',
                    dataSelector: 'accountsName',
                    dataType: 'string',
                    canSort: false,
                  },
                  {
                    title: 'Number',
                    dataSelector: 'rechargeNumber',
                    dataType: 'string',
                    canSort: false,
                  },
                  {
                    title: 'Amount',
                    dataSelector: 'amount',
                    dataType: 'number',
                    align: 'center',
                    CellRenderer: (value) => formatCurrency(value),
                    canSort: false,
                  },
                  {
                    title: 'Operators ID',
                    dataSelector: 'apiOperatorsId',
                    dataType: 'string',
                    align: 'center',
                    canSort: false,
                    CellRenderer: (value) => (value ? value : 'N/A'),
                  },
                  {
                    title: 'Processed On',
                    dataSelector: 'createdOn',
                    dataType: 'string',
                    align: 'center',
                    canSort: false,
                    CellRenderer: (value) => (value ? value : 'N/A'),
                  },
                ]
          }
          fullScreen={true}
          headingBackgorundColor={'#363636'}
          headingTextColor={''}
          tableData={transactions}
          hideDataBackgroundColor={true}
          showBackgroundColor={true}
          showBackgroundColorSelector={'transactionsStatus'}
          showBackgroundColors={[
            {
              value: 'Pending',
              color: '#ff9800',
            },
            {
              value: 'Success',
              color: 'forestgreen',
            },
            {
              value: 'Failure',
              color: 'red',
            },
          ]}
        />
      )}
    </>
  );
};

export default memo(LiveRechargeTransactionsTable);
