import React, { useState, useEffect, useCallback } from 'react';
import {
  faCircleCheck,
  faCircleXmark,
  faRotateRight,
} from '@fortawesome/free-solid-svg-icons';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import NoData from '../../../components/NoData/NoData';
import StatusHighliter from '../../../components/StatusHighliter/StatusHighliter';
import {
  Form,
  Fieldset,
  Label,
  TextInput,
  MessageBox,
  ResetButton,
  Button,
  Dropdown,
  NumberInput,
  CustomConfirmButton,
  CustomButton,
} from '../../../components/FormElements';
import { TableBuilder } from '../../../components/TableElements';
import {
  StyledFiltersContainer,
  StyledSpan,
  StyledRechargeCountsContainer,
  StyledRechargeCounter,
  StyledHeader,
} from '../../../components/Styled';
import Pagination from '../../../components/Pagination/Pagination';
import API from '../../../api';
import {
  ACCOUNT_TYPES,
  API_RESPONSE_TYPES,
  PERMISSION_TYPES,
} from '../../../constants';
import {
  getSearchParams,
  camelCaseToString,
  authData,
  formatCurrency,
  shouldRedirect,
  redirectToPageOne,
} from '../../../utils';
import FiltersButton from '../../../components/FiltersButton/FiltersButton';
import moment from 'moment';

const PaymentGatewayDepositsTable = (props) => {
  const { accountType } = authData.get();
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams('pageNumber') || 1,
    recordsPerPage: getSearchParams('recordsPerPage') || 10,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [paymentGatewayDeposits, setPaymentGatewayDeposits] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [rolesId, setRolesId] = useState('');
  const [accountsId, setAccountsId] = useState('');
  const [status, setStatus] = useState('Pending');
  const [amount, setAmount] = useState('');
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));

  const [upiTransactionId, setUpiTransactionId] = useState('');
  const [gatewayType, setGatewayType] = useState('');
  const [counts, setCounts] = useState({});

  const clearFilters = () => {
    setRolesId('');
    setAccountsId('');
    setStatus('Pending');
    setAmount('');
    setStartDate(moment().format('YYYY-MM-DD'));
    setEndDate(moment().format('YYYY-MM-DD'));
    setUpiTransactionId('');
    setGatewayType('');
  };

  const resetFilters = () => {
    clearFilters();
    readData(accountsId, 'Pending', amount, startDate, endDate);
    readCounts(accountsId, 'Pending', amount, startDate, endDate);
  };

  const readCounts = useCallback(
    (
      accountsId = '',
      status = 'Pending',
      amount = '',
      startDate = '',
      endDate = '',
      upiTransactionId = '',
      gatewayType = ''
    ) => {
      setIsLoading(true);
      setResponseStatus('');
      setResponseMessage('');
      API.get(
        `/accounting/payment-gateway-deposits/counts?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fAccountsId=${accountsId}&fStatus=${status}&fAmount=${amount}&fStartDate=${startDate}&fEndDate=${endDate}&fUpiTransactionId=${upiTransactionId}&fGatewayType=${gatewayType}`
      )
        .then((response) => {
          const { status, message, data } = response.data;
          if (status === API_RESPONSE_TYPES.SUCCESS) {
            setCounts(data[0]);
          } else {
            setResponseStatus(status);
            setResponseMessage(message);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    []
  );

  const readAccounts = () => {
    API.get('/accounts-management/accounts').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setAccounts(data);
      }
    });
  };

  const readData = useCallback(
    (
      accountsId = '',
      status = 'Pending',
      amount = '',
      startDate = '',
      endDate = '',
      upiTransactionId = '',
      gatewayType = ''
    ) => {
      setIsLoading(true);
      setResponseStatus('');
      setResponseMessage('');
      API.get(
        `/accounting/payment-gateway-deposits/?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fAccountsId=${accountsId}&fStatus=${status}&fAmount=${amount}&fStartDate=${startDate}&fEndDate=${endDate}&fUpiTransactionId=${upiTransactionId}&fGatewayType=${gatewayType}`
      )
        .then((response) => {
          const { status, message, data, pageInfo } = response.data;
          if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
            redirectToPageOne();
          } else {
            if (status === API_RESPONSE_TYPES.SUCCESS) {
              setTotalRecords(pageInfo.totalRecords);
              setPaymentGatewayDeposits(data);
            } else {
              setResponseStatus(status);
              setResponseMessage(message);
            }
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [paginationData]
  );

  const reloadTransaction = (id) => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(
      `/accounting/payment-gateway-deposits/status-check/${id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fAccountsId=${accountsId}&fStatus=${status}&fAmount=${amount}&fStartDate=${startDate}&fEndDate=${endDate}&fUpiTransactionId=${upiTransactionId}&fGatewayType=${gatewayType}`
    )
      .then((response) => {
        const { message, data } = response.data;
        if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
          redirectToPageOne();
        } else {
          if (response.data.status === API_RESPONSE_TYPES.SUCCESS) {
            readData(
              accountsId,
              status,
              amount,
              startDate,
              endDate,
              upiTransactionId,
              gatewayType
            );
            readCounts(
              accountsId,
              status,
              amount,
              startDate,
              endDate,
              upiTransactionId,
              gatewayType
            );
          } else {
            setResponseStatus(response.data.status);
            setResponseMessage(message);
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateTransaction = (id, approvalStatus) => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      approvalStatus,
      remark: approvalStatus,
    };
    API.put(
      `/accounting/payment-gateway-deposits/${id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fAccountsId=${accountsId}&fStatus=${status}&fAmount=${amount}&fStartDate=${startDate}&fEndDate=${endDate}&fUpiTransactionId=${upiTransactionId}&fGatewayType=${gatewayType}`,
      formData
    )
      .then((response) => {
        const { status, message, data } = response.data;
        if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
          redirectToPageOne();
        } else {
          if (status === API_RESPONSE_TYPES.FAILURE) {
            setResponseStatus(status);
            setResponseMessage(message);
          } else {
            readData(
              accountsId,
              status,
              amount,
              startDate,
              endDate,
              upiTransactionId,
              gatewayType
            );
            readCounts(
              accountsId,
              status,
              amount,
              startDate,
              endDate,
              upiTransactionId,
              gatewayType
            );
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const reloadData = () => {
    readData(
      accountsId,
      status,
      amount,
      startDate,
      endDate,
      upiTransactionId,
      gatewayType
    );
    readCounts(
      accountsId,
      status,
      amount,
      startDate,
      endDate,
      upiTransactionId,
      gatewayType
    );
  };

  const filterData = (e) => {
    e.preventDefault();
    setIsFiltering(true);
  };

  useEffect(() => {
    if (isFiltering) {
      setPaginationData((paginationData) => ({
        pageNumber: 1,
        recordsPerPage: paginationData.recordsPerPage,
      }));
      setIsFiltering((isFiltering) => !isFiltering);
    }
  }, [isFiltering]);

  useEffect(() => {
    if (
      accountType !== ACCOUNT_TYPES.MASTER_DISTRIBUTORS &&
      accountType !== ACCOUNT_TYPES.DISTRIBUTORS &&
      accountType !== ACCOUNT_TYPES.RETAILERS &&
      accountType !== ACCOUNT_TYPES.API_USERS
    ) {
      readAccounts();
    }
  }, [accountType]);

  useEffect(() => {
    if (!isFiltering) {
      readData(
        accountsId,
        status,
        amount,
        startDate,
        endDate,
        upiTransactionId,
        gatewayType
      );
      readCounts(
        accountsId,
        status,
        amount,
        startDate,
        endDate,
        upiTransactionId,
        gatewayType
      );
    }
  }, [paginationData, readData, readCounts]);

  const { accountsPermissions } = props;
  const canUpdate =
    accountsPermissions.includes(PERMISSION_TYPES.UPDATE) || false;

  const accountsOptions = [{ title: '-- SELECT ACCOUNT --', value: '' }];
  accounts
    .filter((account) => account?.isDeleted === 'No')
    .filter(({ accountsRolesId }) =>
      rolesId !== ''
        ? accountsRolesId === rolesId
        : [2, 3, 4, 5].includes(accountsRolesId)
    )
    .forEach(({ id, firstName, lastName }) =>
      accountsOptions.push({
        title: `${firstName} ${lastName}`,
        value: id,
      })
    );

  const columns = [
    ACCOUNT_TYPES.MASTER_DISTRIBUTORS,
    ACCOUNT_TYPES.DISTRIBUTORS,
    ACCOUNT_TYPES.RETAILERS,
    ACCOUNT_TYPES.API_USERS,
  ].includes(accountType)
    ? [
        {
          title: 'Requested On',
          dataSelector: 'createdOn',
          dataType: 'date',
          align: 'center',
        },
        {
          title: 'Updated On',
          dataSelector: 'updatedOn',
          dataType: 'string',
          CellRenderer: (value) => (value ? value : 'N/A'),
        },
        {
          title: 'Amount',
          dataSelector: 'amount',
          dataType: 'number',
          align: 'center',
          CellRenderer: (value) => formatCurrency(value),
        },
        {
          title: 'UTR Number',
          dataSelector: 'utrNumber',
          dataType: 'string',
          CellRenderer: (value) => (value ? value : 'N/A'),
        },
        {
          title: 'Remark',
          dataSelector: 'description',
          dataType: 'string',
          CellRenderer: (value) => (value ? value : 'N/A'),
        },
        {
          title: 'Transaction Status',
          dataSelector: 'transactionsStatus',
          dataType: 'string',
          align: 'center',
          CellRenderer: (value) =>
            value && (
              <StatusHighliter
                className={
                  value === 'Success'
                    ? 'green'
                    : value === 'Failure'
                    ? 'red'
                    : value === 'Pending'
                    ? 'yellow'
                    : 'blue'
                }
              >
                {value}
              </StatusHighliter>
            ),
        },
        {
          title: 'Gateway Type',
          dataSelector: 'gatewayType',
          dataType: 'string',
          CellRenderer: (value) => (value ? value : 'N/A'),
        },
        {
          title: 'UPI Id',
          dataSelector: 'upiId',
          dataType: 'string',
          CellRenderer: (value) => (value ? value : 'N/A'),
        },
        {
          title: 'UPI Transaction Id',
          dataSelector: 'upiTransactionId',
          dataType: 'string',
          CellRenderer: (value) => (value ? value : 'N/A'),
        },
        {
          title: 'Reject Reason',
          dataSelector: 'rejectReason',
          dataType: 'string',
          CellRenderer: (value) => (value ? value : 'N/A'),
        },
      ]
    : [
        {
          title: '',
          dataSelector: 'id',
          dataType: 'string',
          CellRenderer: (value, item) => (
            <>
              {canUpdate && item.transactionsStatus === 'Pending' && (
                <>
                  {item.gatewayType !== 'UPI' && (
                    <CustomButton
                      icon={faRotateRight}
                      onClick={() => reloadTransaction(value)}
                      style={{ color: '#333f7a' }}
                    />
                  )}
                  <CustomConfirmButton
                    icon={faCircleCheck}
                    title="Approve Dispute"
                    message="Are you sure, you want to approve this dispute ?"
                    proceedButtonText="Approve"
                    cancelButtonText="Cancel"
                    onClick={() => updateTransaction(value, 'Approved')}
                    color="forestgreen"
                    hoverColor="#006200"
                  />
                  <CustomConfirmButton
                    icon={faCircleXmark}
                    title="Reject Dispute"
                    message="Are you sure, you want to reject this dispute ?"
                    proceedButtonText="Reject"
                    cancelButtonText="Cancel"
                    onClick={() => updateTransaction(value, 'Rejected')}
                  />
                </>
              )}
            </>
          ),
        },
        {
          title: 'Account',
          dataSelector: 'firstName',
          dataType: 'string',
          CellRenderer: (value, item) => {
            const { firstName, lastName, roleName, mobileNumber } = item;
            return (
              <>
                {firstName} {lastName}
                <StyledSpan>{mobileNumber}</StyledSpan>
                <StyledSpan>{camelCaseToString(roleName)}</StyledSpan>
              </>
            );
          },
        },
        {
          title: 'Requested On',
          dataSelector: 'createdOn',
          dataType: 'date',
          align: 'center',
        },
        {
          title: 'Updated On',
          dataSelector: 'updatedOn',
          dataType: 'string',
          CellRenderer: (value) => (value ? value : 'N/A'),
        },
        {
          title: 'Amount',
          dataSelector: 'amount',
          dataType: 'number',
          align: 'center',
          CellRenderer: (value) => formatCurrency(value),
        },
        {
          title: 'UTR Number',
          dataSelector: 'utrNumber',
          dataType: 'string',
          CellRenderer: (value) => (value ? value : 'N/A'),
        },
        {
          title: 'Remark',
          dataSelector: 'description',
          dataType: 'string',
          CellRenderer: (value) => (value ? value : 'N/A'),
        },
        {
          title: 'Transaction Status',
          dataSelector: 'transactionsStatus',
          dataType: 'string',
          align: 'center',
          CellRenderer: (value) =>
            value && (
              <StatusHighliter
                className={
                  value === 'Success'
                    ? 'green'
                    : value === 'Failure'
                    ? 'red'
                    : value === 'Pending'
                    ? 'yellow'
                    : 'blue'
                }
              >
                {value}
              </StatusHighliter>
            ),
        },
        {
          title: 'Gateway Type',
          dataSelector: 'gatewayType',
          dataType: 'string',
          CellRenderer: (value) => (value ? value : 'N/A'),
        },
        {
          title: 'UPI Id',
          dataSelector: 'upiId',
          dataType: 'string',
          CellRenderer: (value) => (value ? value : 'N/A'),
        },
        {
          title: 'UPI Transaction Id',
          dataSelector: 'upiTransactionId',
          dataType: 'string',
          CellRenderer: (value) => (value ? value : 'N/A'),
        },
        {
          title: 'Reject Reason',
          dataSelector: 'rejectReason',
          dataType: 'string',
          CellRenderer: (value) => (value ? value : 'N/A'),
        },
      ];

  const [showFilters, setShowFilters] = useState(false);
  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  return (
    <>
      <PageHeading {...props} reloadData={reloadData} />
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer className={showFilters ? 'show' : ''}>
        <Form method="POST" action="#" onSubmit={filterData}>
          {![
            ACCOUNT_TYPES.MASTER_DISTRIBUTORS,
            ACCOUNT_TYPES.DISTRIBUTORS,
            ACCOUNT_TYPES.RETAILERS,
            ACCOUNT_TYPES.API_USERS,
          ].includes(accountType) && (
            <>
              <Fieldset>
                <Label>Role</Label>
                <Dropdown
                  style={{ border: '1px solid #cacaca' }}
                  placeholder="Role"
                  value={rolesId}
                  onChange={(value) => {
                    setAccountsId('');
                    setRolesId(value);
                  }}
                  options={[
                    {
                      title: 'Master Distributors',
                      value: 2,
                    },
                    {
                      title: 'Distributors',
                      value: 3,
                    },
                    {
                      title: 'Retailers',
                      value: 4,
                    },
                    {
                      title: 'API Users',
                      value: 5,
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
              <Fieldset>
                <Label>Account</Label>
                <Dropdown
                  style={{ border: '1px solid #cacaca' }}
                  placeholder="Account"
                  value={accountsId}
                  onChange={setAccountsId}
                  options={accountsOptions}
                  disabled={isLoading}
                />
              </Fieldset>
            </>
          )}
          <Fieldset>
            <Label>Status</Label>
            <Dropdown
              style={{ border: '1px solid #cacaca' }}
              placeholder="Status"
              value={status}
              onChange={setStatus}
              options={[
                {
                  title: '-- SELECT STATUS --',
                  value: '',
                },
                {
                  title: 'All',
                  value: 'All',
                },
                {
                  title: 'Pending',
                  value: 'Pending',
                },
                {
                  title: 'Success',
                  value: 'Success',
                },
                {
                  title: 'Failure',
                  value: 'Failure',
                },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Gateway Type</Label>
            <Dropdown
              style={{ border: '1px solid #cacaca' }}
              placeholder="Gateway Type"
              value={gatewayType}
              onChange={setGatewayType}
              options={[
                {
                  title: '-- SELECT GATEWAY TYPE --',
                  value: '',
                },
                {
                  title: 'Gateway',
                  value: 'Gateway',
                },
                {
                  title: 'UPI',
                  value: 'UPI',
                },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>UPI Transaction Id</Label>
            <TextInput
              value={upiTransactionId}
              onChange={setUpiTransactionId}
              placeholder="UPI Transaction Id"
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Label>Amount</Label>
            <NumberInput
              value={amount}
              onChange={setAmount}
              placeholder="Amount"
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Label>Start Date</Label>
            <TextInput
              type="date"
              value={startDate}
              onChange={setStartDate}
              placeholder="Start Date"
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Label>End Date</Label>
            <TextInput
              type="date"
              value={endDate}
              onChange={setEndDate}
              placeholder="End Date"
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading === true} type="submit">
              Filter
            </Button>
            <ResetButton
              disabled={isLoading === true}
              type="button"
              onClick={resetFilters}
            >
              Clear
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <StyledRechargeCountsContainer>
        {counts?.successAmount && (
          <StyledRechargeCounter color={'forestgreen'}>
            <StyledHeader>{`${formatCurrency(counts?.successAmount)} ( ${
              counts.successCount
            } )`}</StyledHeader>
          </StyledRechargeCounter>
        )}
        {counts?.failureAmount && (
          <StyledRechargeCounter color={'red'}>
            <StyledHeader>{`${formatCurrency(counts?.failureAmount)} ( ${
              counts.failureCount
            } )`}</StyledHeader>
          </StyledRechargeCounter>
        )}
        {counts?.pendingAmount && (
          <StyledRechargeCounter color={'#ff9800'}>
            <StyledHeader>{`${formatCurrency(counts?.pendingAmount)} ( ${
              counts.pendingCount
            } )`}</StyledHeader>
          </StyledRechargeCounter>
        )}
      </StyledRechargeCountsContainer>
      <LoadingSpinner
        isLoading={
          responseStatus === '' &&
          paymentGatewayDeposits?.length === 0 &&
          isLoading
        }
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          paymentGatewayDeposits?.length === 0
        }
        message={`No payment gateway deposits found`}
      />
      {responseStatus === API_RESPONSE_TYPES.FAILURE && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {paymentGatewayDeposits?.length > 0 && (
        <>
          <TableBuilder
            isLoading={paymentGatewayDeposits?.length !== 0 && isLoading}
            tableHeadings={columns}
            tableData={paymentGatewayDeposits}
          />
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
        </>
      )}
    </>
  );
};

export default withAppHOC(PaymentGatewayDepositsTable);
