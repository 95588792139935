import React, { useState, useEffect, useCallback } from 'react';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import NoData from '../../../components/NoData/NoData';
import {
  MessageBox,
  Form,
  Fieldset,
  Button,
  ResetButton,
  Dropdown,
  TextInput,
  Label,
} from '../../../components/FormElements';
import { TableBuilder } from '../../../components/TableElements';
import { StyledSpan, StyledFiltersContainer } from '../../../components/Styled';
import API from '../../../api';
import { ACCOUNT_TYPES, API_RESPONSE_TYPES } from '../../../constants';
import { formatCurrency, authData } from '../../../utils';
import moment from 'moment';

const AccountsDayBookTable = (props) => {
  const { accountType } = authData.get();
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [daybookData, setDaybookData] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [rolesId, setRolesId] = useState('');
  const [accountsId, setAccountsId] = useState('');
  const [startDate, setStartDate] = useState(
    moment().subtract(1, 'day').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState(
    moment().subtract(1, 'day').format('YYYY-MM-DD')
  );

  const resetForm = () => {
    setRolesId('');
    setAccountsId('');
    setStartDate(moment().subtract(1, 'day').format('YYYY-MM-DD'));
    setEndDate(moment().subtract(1, 'day').format('YYYY-MM-DD'));
    readData(
      '',
      moment().subtract(1, 'day').format('YYYY-MM-DD'),
      moment().subtract(1, 'day').format('YYYY-MM-DD')
    );
  };

  const readAccounts = () => {
    API.get('/accounts-management/accounts').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setAccounts(data);
      }
    });
  };

  const readData = useCallback(
    (accountsId = '', startDate = '', endDate = '') => {
      setIsLoading(true);
      setResponseStatus('');
      setResponseMessage('');
      API.get(
        `/reports/accounts-day-book/?accountsId=${accountsId}&startDate=${startDate}&endDate=${endDate}`
      )
        .then((response) => {
          const { status, message, data } = response.data;
          if (status === API_RESPONSE_TYPES.FAILURE) {
            setResponseStatus(status);
            setResponseMessage(message);
          }
          setDaybookData(data);
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    []
  );

  const filterData = (e) => {
    e.preventDefault();
    readData(accountsId, startDate, endDate);
  };

  useEffect(() => {
    if (
      ![
        ACCOUNT_TYPES.MASTER_DISTRIBUTORS,
        ACCOUNT_TYPES.DISTRIBUTORS,
        ACCOUNT_TYPES.RETAILERS,
        ACCOUNT_TYPES.API_USERS,
      ].includes(accountType)
    ) {
      readAccounts();
    }
    readData(accountsId, startDate, endDate);
  }, [accountType]);

  const rolesOptions = [
    { title: '-- SELECT ROLE --', value: '' },
    { title: 'Master Distributors', value: 2 },
    { title: 'Distributors', value: 3 },
    { title: 'Retailers', value: 4 },
    { title: 'API Users', value: 5 },
  ];
  const accountsOptions = [{ title: '-- SELECT ACCOUNT --', value: '' }];
  accounts
    .filter((account) => account?.isDeleted === 'No')
    .filter(({ accountsRolesId }) =>
      rolesId ? accountsRolesId === rolesId : true
    )
    .forEach(({ id, firstName, lastName, isEntity, entityName }) =>
      accountsOptions.push({
        title:
          isEntity === 'Yes' ? `${entityName}` : `${firstName} ${lastName}`,
        value: id,
      })
    );

  return (
    <>
      <PageHeading {...props} canReloadProp={false} />
      <StyledFiltersContainer className="show">
        <Form method="POST" action="#" onSubmit={filterData}>
          {![
            ACCOUNT_TYPES.MASTER_DISTRIBUTORS,
            ACCOUNT_TYPES.DISTRIBUTORS,
            ACCOUNT_TYPES.RETAILERS,
            ACCOUNT_TYPES.API_USERS,
          ].includes(accountType) && (
            <>
              <Fieldset>
                <Label>Role</Label>
                <Dropdown
                  style={{ border: '1px solid #cacaca' }}
                  placeholder="Role"
                  value={rolesId}
                  onChange={(value) => {
                    setRolesId(value);
                    setAccountsId('');
                  }}
                  options={rolesOptions}
                  disabled={isLoading}
                />
              </Fieldset>
              <Fieldset>
                <Label>Account</Label>
                <Dropdown
                  style={{ border: '1px solid #cacaca' }}
                  placeholder="Account"
                  value={accountsId}
                  onChange={setAccountsId}
                  options={accountsOptions}
                  disabled={isLoading}
                />
              </Fieldset>
            </>
          )}
          <Fieldset>
            <Label>Start Date</Label>
            <TextInput
              type="date"
              value={startDate}
              onChange={setStartDate}
              placeholder="Start Date"
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Label>End Date</Label>
            <TextInput
              type="date"
              value={endDate}
              onChange={setEndDate}
              placeholder="End Date"
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading === true} type="submit">
              Search
            </Button>
            <ResetButton
              disabled={isLoading === true}
              type="button"
              onClick={resetForm}
            >
              Clear
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={
          responseStatus === '' && daybookData?.length === 0 && isLoading
        }
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          daybookData?.length === 0
        }
        message={`No data found`}
      />
      <MessageBox status={responseStatus} message={responseMessage} />
      {daybookData?.length > 0 && (
        <TableBuilder
          isLoading={daybookData?.length !== 0 && isLoading}
          tableHeadings={[
            {
              title: 'Account',
              dataSelector: 'accountsName',
              dataType: 'string',
              CellRenderer: (value, item) => (
                <>
                  {value}
                  <StyledSpan>{item.roleName}</StyledSpan>
                </>
              ),
            },
            {
              title: 'Opening Balance',
              dataSelector: 'openingBalance',
              dataType: 'string',
              CellRenderer: (value) => formatCurrency(value),
            },
            {
              title: 'Todays Purchase',
              dataSelector: 'todaysPurchase',
              dataType: 'string',
              CellRenderer: (value) => (value ? formatCurrency(value) : 0),
            },
            {
              title: 'Refunds Amount',
              dataSelector: 'refundsAmount',
              dataType: 'string',
              CellRenderer: (value) => formatCurrency(value),
            },
            {
              title: 'Total Sale',
              dataSelector: 'totalSale',
              dataType: 'string',
              CellRenderer: (value) => formatCurrency(value),
            },
            {
              title: 'Commission Earned',
              dataSelector: 'rechargeCommission',
              dataType: 'string',
              CellRenderer: (value) => formatCurrency(value),
            },
            {
              title: 'Closing Balance',
              dataSelector: 'closingBalance',
              dataType: 'string',
              CellRenderer: (value) => formatCurrency(value),
            },
          ]}
          tableData={daybookData}
        />
      )}
    </>
  );
};

export default withAppHOC(AccountsDayBookTable);
