import React, { useState, useEffect, useCallback } from 'react';
import {
  faEdit,
  faRotateRight,
  faIndianRupeeSign,
  faEye,
  faRefresh,
} from '@fortawesome/free-solid-svg-icons';
import exportFromJSON from 'export-from-json';
import JSONPretty from 'react-json-pretty';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import NoData from '../../../components/NoData/NoData';
import StatusHighliter from '../../../components/StatusHighliter/StatusHighliter';
import {
  MessageBox,
  CustomButton,
  Label,
  Fieldset,
  TextInput,
  Button,
  Form,
  ResetButton,
  Dropdown,
  FormContainer,
  UpdateButton,
  CustomConfirmButton,
} from '../../../components/FormElements';
import { TableBuilder } from '../../../components/TableElements';
import Modal from '../../../components/Modal';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import {
  StyledFiltersContainer,
  StyledModalContainer,
  StyledApiCallLogsContainer,
  StyledBodyContainer,
  StyledHeading,
  StyledContent,
  StyledSpan,
} from '../../../components/Styled';
import Pagination from '../../../components/Pagination/Pagination';
import API from '../../../api';
import {
  ACCOUNT_TYPES,
  API_RESPONSE_TYPES,
  PERMISSION_TYPES,
} from '../../../constants';
import {
  getSearchParams,
  authData,
  formatCurrency,
  shouldRedirect,
  redirectToPageOne,
} from '../../../utils';
import FiltersButton from '../../../components/FiltersButton/FiltersButton';
import Accordination from '../../../components/Accordination/Accordination';
import { Grid, GridItem } from '../../../components/Grid';

const PendingTransactionsTable = (props) => {
  const { accountType } = authData.get();
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams('pageNumber') || 1,
    recordsPerPage: getSearchParams('recordsPerPage') || 100,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalTransactionsId, setModalTransactionsId] = useState('');
  const [apiTransactionsId, setApiTransactionsId] = useState('');
  const [apiOperatorsId, setApiOperatorsId] = useState('');
  const [operators, setOperators] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [number, setNumber] = useState('');
  const [operatorsId, setOperatorsId] = useState('');
  const [transactionsId, setTransactionsId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [apiProviders, setApiProviders] = useState([]);
  const [apiProvidersId, setApiProvidersId] = useState('');
  const [rolesId, setRolesId] = useState('');
  const [accountsId, setAccountsId] = useState('');
  const [accounts, setAccounts] = useState([]);
  const [modalType, setModalType] = useState('');
  const [logs, setLogs] = useState([]);
  const [reprocessApiProvidersId, setReprocessApiProvidersId] = useState('');
  const [reprocessTransactionsId, setReprocessTransactionsId] = useState('');

  const readApiLogs = (id) => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/transactions/logs/${id}`)
      .then((response) => {
        const { status, data } = response.data;
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setLogs(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const toggleApiLogs = (id) => {
    setModalType('API_LOGS');
    setShowModal(true);
    readApiLogs(id);
  };

  const toggleReprocess = () => {
    setModalType('REPROCESS');
    setShowModal(true);
  };

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    setRolesId('');
    setAccountsId('');
    setNumber('');
    setTransactionsId('');
    setApiProvidersId('');
    setOperatorsId('');
    setStartDate('');
    setEndDate('');
    readData();
  };

  const resetModalForm = () => {
    setApiTransactionsId('');
    setApiOperatorsId('');
    setReprocessApiProvidersId('');
  };

  const readAccounts = () => {
    API.get('/accounts-management/accounts').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setAccounts(data);
      }
    });
  };

  const readOperators = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/recharge-configurations/operators`)
      .then((response) => {
        const { status, data } = response.data;
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOperators(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const readApiProviders = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/recharge-configurations/api-providers`)
      .then((response) => {
        const { status, data } = response.data;
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setApiProviders(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const readData = useCallback(
    (
      number = '',
      operatorsId = '',
      transactionsId = '',
      startDate = '',
      endDate = '',
      apiProvidersId = '',
      accountsId = ''
    ) => {
      setIsLoading(true);
      setResponseStatus('');
      setResponseMessage('');
      API.get(
        `/transactions/?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&faccountsId=${accountsId}&number=${number}&apiProvidersId=${apiProvidersId}&operatorsId=${operatorsId}&transactionsId=${transactionsId}&startDate=${startDate}&endDate=${endDate}&transactionsStatus=Pending&disputeStatus=`
      )
        .then((response) => {
          const { status, data, pageInfo } = response.data;
          if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
            redirectToPageOne();
          } else {
            if (status === API_RESPONSE_TYPES.SUCCESS) {
              setTotalRecords(pageInfo.totalRecords);
            }
            setTransactions(data);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [paginationData]
  );

  const refundTransaction = (id) => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.put(
      `/transactions/refund/${id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&faccountsId=${accountsId}&number=${number}&apiProvidersId=${apiProvidersId}&operatorsId=${operatorsId}&transactionsId=${transactionsId}&startDate=${startDate}&endDate=${endDate}&transactionsStatus=Pending&disputeStatus=`
    )
      .then((response) => {
        const { status, message, data, pageInfo } = response.data;
        if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
          redirectToPageOne();
        } else {
          setResponseStatus(status);
          setResponseMessage(message);
          if (status === API_RESPONSE_TYPES.SUCCESS) {
            setTransactions(data);
            setTotalRecords(pageInfo.totalRecords);
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
        setTimeout(() => {
          resetResponseData();
        }, 3000);
      });
  };

  const updateTransaction = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.put(
      `/transactions/update/${modalTransactionsId}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&faccountsId=${accountsId}&number=${number}&apiProvidersId=${apiProvidersId}&operatorsId=${operatorsId}&transactionsId=${transactionsId}&startDate=${startDate}&endDate=${endDate}&transactionsStatus=Pending&disputeStatus=`,
      {
        apiTransactionsId,
        apiOperatorsId,
      }
    )
      .then((response) => {
        const { status, message, data, pageInfo } = response.data;
        if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
          redirectToPageOne();
        } else {
          setResponseStatus(status);
          setResponseMessage(message);
          if (status === API_RESPONSE_TYPES.SUCCESS) {
            setTransactions(data);
            setTotalRecords(pageInfo.totalRecords);
            resetModalForm();
            setTimeout(() => {
              setShowModal(false);
              setModalTransactionsId('');
              resetResponseData();
            }, 3000);
          } else {
            setTimeout(() => {
              resetResponseData();
            }, 3000);
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const reloadTransaction = (id) => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(
      `/transactions/reload/${id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&faccountsId=${accountsId}&number=${number}&apiProvidersId=${apiProvidersId}&operatorsId=${operatorsId}&transactionsId=${transactionsId}&startDate=${startDate}&endDate=${endDate}&transactionsStatus=Pending&disputeStatus=`
    )
      .then((response) => {
        const { status, message, data, pageInfo } = response.data;
        if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
          redirectToPageOne();
        } else {
          setResponseStatus(status);
          setResponseMessage(message);
          if (status === API_RESPONSE_TYPES.SUCCESS) {
            setTransactions(data);
            setTotalRecords(pageInfo.totalRecords);
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
        setTimeout(() => {
          resetResponseData();
        }, 3000);
      });
  };

  const reloadData = () => {
    readData(
      number,
      operatorsId,
      transactionsId,
      startDate,
      endDate,
      apiProvidersId,
      accountsId
    );
  };

  const filterData = (e) => {
    e.preventDefault();
    setIsFiltering(true);
  };

  const onClickExport = () => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(
      `/transactions/export?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&faccountsId=${accountsId}&number=${number}&apiProvidersId=${apiProvidersId}&operatorsId=${operatorsId}&transactionsId=${transactionsId}&startDate=${startDate}&endDate=${endDate}&transactionsStatus=Pending&disputeStatus=`
    )
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          exportFromJSON({
            data,
            fileName: 'transactions',
            exportType: exportFromJSON.types.csv,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onClickHeavyReload = () => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(
      `/transactions/heavy-reload/?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&faccountsId=${accountsId}&number=${number}&apiProvidersId=${apiProvidersId}&operatorsId=${operatorsId}&transactionsId=${transactionsId}&startDate=${startDate}&endDate=${endDate}&transactionsStatus=Pending&disputeStatus=`
    )
      .then((response) => {
        const { status, message, data } = response.data;
        if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
          redirectToPageOne();
        } else {
          if (status === API_RESPONSE_TYPES.FAILURE) {
            setResponseStatus(status);
            setResponseMessage(message);
          }
          setTransactions(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onClickReprocess = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(
      `/transactions/reprocess-transactions?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&faccountsId=${accountsId}&number=${number}&apiProvidersId=${apiProvidersId}&operatorsId=${operatorsId}&transactionsId=${transactionsId}&startDate=${startDate}&endDate=${endDate}&transactionsStatus=Pending&disputeStatus=&reprocessApiProvidersId=${reprocessApiProvidersId}&reprocessTransactionsId=${reprocessTransactionsId}`
    )
      .then((response) => {
        const { status, message, data } = response.data;
        if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
          redirectToPageOne();
        } else {
          setResponseStatus(status);
          setResponseMessage(message);
          if (status === API_RESPONSE_TYPES.SUCCESS) {
            setTimeout(() => {
              setTransactions(data);
              resetResponseData();
              resetModalForm();
              setShowModal(false);
              setModalType('');
              setLogs([]);
              setReprocessApiProvidersId('');
              setReprocessTransactionsId('');
            }, 3000);
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isFiltering) {
      setPaginationData((paginationData) => ({
        pageNumber: 1,
        recordsPerPage: paginationData.recordsPerPage,
      }));
      setIsFiltering((isFiltering) => !isFiltering);
    }
  }, [isFiltering]);

  useEffect(() => {
    readOperators();
    if (
      ![
        ACCOUNT_TYPES.MASTER_DISTRIBUTORS,
        ACCOUNT_TYPES.DISTRIBUTORS,
        ACCOUNT_TYPES.RETAILERS,
        ACCOUNT_TYPES.API_USERS,
      ].includes(accountType)
    ) {
      readApiProviders();
    }
  }, [readOperators, readApiProviders, accountType]);

  useEffect(() => {
    if (
      ![
        ACCOUNT_TYPES.MASTER_DISTRIBUTORS,
        ACCOUNT_TYPES.DISTRIBUTORS,
        ACCOUNT_TYPES.RETAILERS,
        ACCOUNT_TYPES.API_USERS,
      ].includes(accountType)
    ) {
      readAccounts();
    }
  }, [accountType]);

  useEffect(() => {
    if (!isFiltering) {
      readData(
        number,
        operatorsId,
        transactionsId,
        startDate,
        endDate,
        apiProvidersId,
        accountsId
      );
    }
  }, [paginationData, readData, accountType]);

  const { accountsPermissions } = props;
  const canUpdate =
    accountsPermissions.includes(PERMISSION_TYPES.UPDATE) || false;
  const canReload =
    accountsPermissions.includes(PERMISSION_TYPES.RELOAD) || false;
  const canRefund =
    accountsPermissions.includes(PERMISSION_TYPES.REFUND) || false;
  const canExport =
    accountsPermissions.includes(PERMISSION_TYPES.EXPORT) || false;
  const canHeavyReload =
    accountsPermissions.includes(PERMISSION_TYPES.HEAVY_RELOAD) || false;
  const canTrack =
    accountsPermissions.includes(PERMISSION_TYPES.TRACK) || false;
  const canReprocess =
    accountsPermissions.includes(PERMISSION_TYPES.REPROCESS) || false;

  const rolesOptions = [{ title: '-- SELECT ROLE --', value: '' }];
  if (accountType === ACCOUNT_TYPES.MASTER_DISTRIBUTORS) {
    [
      {
        id: 3,
        roleName: 'Distributors',
      },
      {
        id: 4,
        roleName: 'Retailers',
      },
    ].forEach(({ id, roleName }) =>
      rolesOptions.push({
        title: roleName,
        value: id,
      })
    );
  } else if (accountType === ACCOUNT_TYPES.DISTRIBUTORS) {
    [
      {
        id: 4,
        roleName: 'Retailers',
      },
    ].forEach(({ id, roleName }) =>
      rolesOptions.push({
        title: roleName,
        value: id,
      })
    );
  } else {
    [
      {
        id: 2,
        roleName: 'Master Distributors',
      },
      {
        id: 3,
        roleName: 'Distributors',
      },
      {
        id: 4,
        roleName: 'Retailers',
      },
      {
        id: 5,
        roleName: 'Api Users',
      },
    ].forEach(({ id, roleName }) =>
      rolesOptions.push({
        title: roleName,
        value: id,
      })
    );
  }

  const accountsOptions = [{ title: '-- SELECT ACCOUNT --', value: '' }];
  accounts
    .filter((account) => account?.isDeleted === 'No')
    .filter(({ accountsRolesId }) =>
      rolesId !== ''
        ? accountsRolesId === rolesId
        : [2, 3, 4, 5].includes(accountsRolesId)
    )
    .forEach(({ id, firstName, lastName }) =>
      accountsOptions.push({
        title: `${firstName} ${lastName}`,
        value: id,
      })
    );

  const operatorsOptions = [{ title: '-- SELECT OPERATOR --', value: '' }];
  operators.forEach(({ id, operatorName }) =>
    operatorsOptions.push({
      title: operatorName,
      value: id,
    })
  );

  const apiProvidersOptions = [
    { title: '-- SELECT API PROVIDER --', value: '' },
  ];
  apiProviders.forEach(({ id, providerName }) =>
    apiProvidersOptions.push({
      title: providerName,
      value: id,
    })
  );

  const [showFilters, setShowFilters] = useState(false);
  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  return (
    <>
      <PageHeading
        {...props}
        reloadData={reloadData}
        showExport={canExport}
        onClickExport={onClickExport}
        showHeavyReload={canHeavyReload}
        onClickHeavyReload={onClickHeavyReload}
        showCanReprocess={canReprocess}
        onClickReprocess={toggleReprocess}
      />
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer className={showFilters ? 'show' : ''}>
        <Form method="POST" action="#" onSubmit={filterData}>
          {![
            ACCOUNT_TYPES.MASTER_DISTRIBUTORS,
            ACCOUNT_TYPES.DISTRIBUTORS,
            ACCOUNT_TYPES.RETAILERS,
            ACCOUNT_TYPES.API_USERS,
          ].includes(accountType) && (
            <>
              <Fieldset>
                <Label>Role</Label>
                <Dropdown
                  style={{ border: '1px solid #cacaca' }}
                  placeholder="Role"
                  value={rolesId}
                  onChange={(value) => {
                    setAccountsId('');
                    setRolesId(value);
                  }}
                  options={rolesOptions}
                  disabled={isLoading}
                />
              </Fieldset>
              <Fieldset>
                <Label>Account</Label>
                <Dropdown
                  style={{ border: '1px solid #cacaca' }}
                  placeholder="Account"
                  value={accountsId}
                  onChange={setAccountsId}
                  options={accountsOptions}
                  disabled={isLoading}
                />
              </Fieldset>
            </>
          )}
          <Fieldset>
            <Label>Mobile/Account Number</Label>
            <TextInput
              value={number}
              onChange={setNumber}
              placeholder="Mobile/Account Number"
              maxLength={100}
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Label>Transaction ID</Label>
            <TextInput
              value={transactionsId}
              onChange={setTransactionsId}
              placeholder="Transactions ID"
              maxLength={100}
              disabled={isLoading === true}
            />
          </Fieldset>
          {![
            ACCOUNT_TYPES.MASTER_DISTRIBUTORS,
            ACCOUNT_TYPES.DISTRIBUTORS,
            ACCOUNT_TYPES.RETAILERS,
            ACCOUNT_TYPES.API_USERS,
          ].includes(accountType) && (
            <Fieldset>
              <Label>API Provider</Label>
              <Dropdown
                style={{ border: '1px solid #cacaca' }}
                placeholder="API Provider"
                value={apiProvidersId}
                onChange={setApiProvidersId}
                options={apiProvidersOptions}
                disabled={isLoading}
              />
            </Fieldset>
          )}
          <Fieldset>
            <Label>Operator</Label>
            <Dropdown
              placeholder="Operator"
              value={operatorsId}
              onChange={setOperatorsId}
              options={operatorsOptions}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Start Date</Label>
            <TextInput
              type="date"
              value={startDate}
              onChange={setStartDate}
              placeholder="Start Date"
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Label>End Date</Label>
            <TextInput
              type="date"
              value={endDate}
              onChange={setEndDate}
              placeholder="End Date"
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading === true} type="submit">
              Filter
            </Button>
            <ResetButton
              disabled={isLoading === true}
              type="button"
              onClick={resetForm}
            >
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={
          !showModal &&
          responseStatus === '' &&
          transactions?.length === 0 &&
          isLoading
        }
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          transactions?.length === 0
        }
        message={`No transactions found`}
      />
      {!showModal && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {transactions?.length > 0 && (
        <>
          {[
            ACCOUNT_TYPES.MASTER_DISTRIBUTORS,
            ACCOUNT_TYPES.DISTRIBUTORS,
            ACCOUNT_TYPES.RETAILERS,
            ACCOUNT_TYPES.API_USERS,
          ].includes(accountType) ? (
            <TableBuilder
              isLoading={!showModal && transactions?.length !== 0 && isLoading}
              tableHeadings={[
                {
                  title: '',
                  dataSelector: 'id',
                  dataType: 'string',
                  CellRenderer: (value) => (
                    <>
                      {canTrack && (
                        <CustomButton
                          icon={faEye}
                          onClick={() => toggleApiLogs(value)}
                          style={{ color: 'orange' }}
                        />
                      )}
                    </>
                  ),
                },
                {
                  title: 'Mobile/Account Number',
                  dataSelector: 'rechargeNumber',
                  dataType: 'string',
                },
                {
                  title: 'Operator',
                  dataSelector: 'operatorName',
                  dataType: 'string',
                  CellRenderer: (value) => value,
                },
                {
                  title: 'Amount',
                  dataSelector: 'amount',
                  dataType: 'number',
                  align: 'center',
                  CellRenderer: (value) => formatCurrency(value),
                },
                {
                  title: 'Actual Amount',
                  dataSelector: 'actualAmount',
                  dataType: 'number',
                  align: 'center',
                  CellRenderer: (value) => formatCurrency(value),
                },
                {
                  title: 'Commission',
                  dataSelector: 'rechargeCommission',
                  dataType: 'number',
                  align: 'center',
                  CellRenderer: (value) => formatCurrency(value),
                },
                {
                  title: 'Transaction Status',
                  dataSelector: 'processedStatus',
                  dataType: 'string',
                  align: 'center',
                  CellRenderer: (value) =>
                    value && (
                      <StatusHighliter
                        className={
                          value === 'Success'
                            ? 'green'
                            : value === 'Failure'
                            ? 'red'
                            : value === 'Pending'
                            ? 'yellow'
                            : 'blue'
                        }
                      >
                        {value}
                      </StatusHighliter>
                    ),
                },
                {
                  title: 'API Operator ID',
                  dataSelector: 'apiOperatorsId',
                  dataType: 'string',
                  align: 'center',
                  CellRenderer: (value) => (value ? value : 'N/A'),
                },
                {
                  title: 'API Users Transaction ID',
                  dataSelector: 'apiUsersTransactionsId',
                  dataType: 'string',
                  align: 'center',
                  requiredRoles: [ACCOUNT_TYPES.API_USERS],
                  CellRenderer: (value) => (value ? value : 'N/A'),
                },
                {
                  title: 'Transaction Date',
                  dataSelector: 'createdOn',
                  dataType: 'date',
                  align: 'center',
                },
                {
                  title: 'Recharge Circle',
                  dataSelector: 'rechargeCircle',
                  dataType: 'string',
                  CellRenderer: (value) => (value ? value : 'N/A'),
                },
              ]}
              tableData={transactions}
            />
          ) : [ACCOUNT_TYPES.STAFF].includes(accountType) ? (
            <TableBuilder
              isLoading={!showModal && transactions?.length !== 0 && isLoading}
              tableHeadings={[
                {
                  title: '',
                  dataSelector: 'id',
                  sticky: true,
                  canSort: false,
                  width: '100px',
                  CellRenderer: (value, item) => (
                    <>
                      {canTrack && (
                        <CustomButton
                          icon={faEye}
                          onClick={() => toggleApiLogs(value)}
                          style={{ color: 'orange' }}
                        />
                      )}
                      {canUpdate &&
                        (item.processedStatus === 'Success' ||
                          item.processedStatus === 'Pending') && (
                          <CustomButton
                            icon={faEdit}
                            onClick={() => {
                              setModalTransactionsId(value);
                              setApiOperatorsId(item.apiOperatorsId);
                              setApiTransactionsId(item.apiTransactionsId);
                              setShowModal(true);
                            }}
                            style={{ color: '#0095ff' }}
                          />
                        )}
                      {canReload && (
                        <CustomButton
                          icon={faRotateRight}
                          onClick={() => reloadTransaction(value)}
                          style={{ color: '#333f7a' }}
                        />
                      )}
                      {canRefund &&
                        (item.processedStatus === 'Success' ||
                          item.processedStatus === 'Pending') && (
                          <CustomConfirmButton
                            icon={faIndianRupeeSign}
                            title="Refund"
                            message="Are you sure, you want to refund this transaction ?"
                            proceedButtonText="Refund"
                            cancelButtonText="Cancel"
                            onClick={() => refundTransaction(value)}
                            color="forestgreen"
                            hoverColor="#006200"
                          />
                        )}
                      {canReprocess && (
                        <CustomButton
                          icon={faRefresh}
                          onClick={() => {
                            setReprocessTransactionsId(value);
                            toggleReprocess();
                          }}
                          style={{ color: 'blue' }}
                        />
                      )}
                    </>
                  ),
                },
                {
                  title: 'Recharge ID',
                  dataSelector: 'id',
                  dataType: 'number',
                },
                {
                  title: 'Date & Time',
                  dataSelector: 'createdOn',
                  dataType: 'date',
                  CellRenderer: (value, item) => {
                    const splitedVal = value.split(' ');
                    return (
                      <>
                        <span style={{ display: 'block', margin: '10px 0px' }}>
                          {splitedVal[0]}
                        </span>
                        <span style={{ display: 'block', margin: '10px 0px' }}>
                          {splitedVal[1]} {splitedVal[2]}
                        </span>
                      </>
                    );
                  },
                },
                {
                  title: 'Account Details',
                  dataSelector: 'accountsName',
                  dataType: 'string',
                  CellRenderer: (value, item) => (
                    <>
                      {value}
                      <StyledSpan>{item.accountsMobileNumber}</StyledSpan>
                      <StyledSpan>{item.accountsRoleName}</StyledSpan>
                    </>
                  ),
                },
                {
                  title: 'Recharge Details',
                  dataSelector: 'rechargeNumber',
                  dataType: 'string',
                  CellRenderer: (value, item) => (
                    <>
                      {value}
                      {item.operatorName && (
                        <StyledSpan>{item.operatorName}</StyledSpan>
                      )}
                      {item.rechargeCircle &&
                        item.rechargeCircle !== 'null' && (
                          <StyledSpan>{item.rechargeCircle}</StyledSpan>
                        )}
                    </>
                  ),
                },
                {
                  title: 'Transaction Status',
                  dataSelector: 'processedStatus',
                  dataType: 'string',
                  align: 'center',
                  CellRenderer: (value) =>
                    value && (
                      <StatusHighliter
                        className={
                          value === 'Success'
                            ? 'green'
                            : value === 'Failure'
                            ? 'red'
                            : value === 'Pending'
                            ? 'yellow'
                            : 'blue'
                        }
                      >
                        {value}
                      </StatusHighliter>
                    ),
                },
                {
                  title: 'Operator ID',
                  dataSelector: 'apiOperatorsId',
                  dataType: 'string',
                  align: 'center',
                  CellRenderer: (value) => (value ? value : 'N/A'),
                },
                {
                  title: 'Amount',
                  dataSelector: 'amount',
                  dataType: 'number',
                  CellRenderer: (value, item) => (
                    <>
                      RC Amount : {formatCurrency(value)}
                      <StyledSpan>
                        Debited Amount : {formatCurrency(item.actualAmount)}
                      </StyledSpan>
                      <StyledSpan>
                        Commitiion :{formatCurrency(item.rechargeCommission)}
                      </StyledSpan>
                    </>
                  ),
                },
                {
                  title: 'Supervisor 1',
                  dataSelector: 'supervisorsCommission1',
                  dataType: 'number',
                  CellRenderer: (value, item) => (
                    <>
                      Commission : {formatCurrency(value)}
                      {item.supervisorsName1 &&
                        item.supervisorsName1 !== 'null' && (
                          <StyledSpan>{item.supervisorsName1}</StyledSpan>
                        )}
                    </>
                  ),
                },
                {
                  title: 'Supervisor 2',
                  dataSelector: 'supervisorsCommission2',
                  dataType: 'number',
                  CellRenderer: (value, item) => (
                    <>
                      Commission : {formatCurrency(value)}
                      {item.supervisorsName2 &&
                        item.supervisorsName2 !== 'null' && (
                          <StyledSpan>{item.supervisorsName2}</StyledSpan>
                        )}
                    </>
                  ),
                },
                {
                  title: 'API Users Transaction ID',
                  dataSelector: 'apiUsersTransactionsId',
                  dataType: 'string',
                  align: 'center',
                  CellRenderer: (value) => (value ? value : 'N/A'),
                },
              ]}
              tableData={transactions}
            />
          ) : (
            <TableBuilder
              isLoading={!showModal && transactions?.length !== 0 && isLoading}
              tableHeadings={[
                {
                  title: '',
                  dataSelector: 'id',
                  sticky: true,
                  canSort: false,
                  width: '100px',
                  CellRenderer: (value, item) => (
                    <>
                      {canTrack && (
                        <CustomButton
                          icon={faEye}
                          onClick={() => toggleApiLogs(value)}
                          style={{ color: 'orange' }}
                        />
                      )}
                      {canUpdate &&
                        (item.processedStatus === 'Success' ||
                          item.processedStatus === 'Pending') && (
                          <CustomButton
                            icon={faEdit}
                            onClick={() => {
                              setModalTransactionsId(value);
                              setShowModal(true);
                            }}
                            style={{ color: '#0095ff' }}
                          />
                        )}
                      {canReload && (
                        <CustomButton
                          icon={faRotateRight}
                          onClick={() => reloadTransaction(value)}
                          style={{ color: '#333f7a' }}
                        />
                      )}
                      {canRefund &&
                        (item.processedStatus === 'Success' ||
                          item.processedStatus === 'Pending') && (
                          <CustomConfirmButton
                            icon={faIndianRupeeSign}
                            title="Refund"
                            message="Are you sure, you want to refund this transaction ?"
                            proceedButtonText="Refund"
                            cancelButtonText="Cancel"
                            onClick={() => refundTransaction(value)}
                            color="forestgreen"
                            hoverColor="#006200"
                          />
                        )}
                      {canReprocess && (
                        <CustomButton
                          icon={faRefresh}
                          onClick={() => {
                            setReprocessTransactionsId(value);
                            toggleReprocess();
                          }}
                          style={{ color: 'blue' }}
                        />
                      )}
                    </>
                  ),
                },
                {
                  title: 'Recharge ID',
                  dataSelector: 'id',
                  dataType: 'number',
                },
                {
                  title: 'Date & Time',
                  dataSelector: 'createdOn',
                  dataType: 'date',
                  CellRenderer: (value, item) => {
                    const splitedVal = value.split(' ');
                    return (
                      <>
                        <span style={{ display: 'block', margin: '10px 0px' }}>
                          {splitedVal[0]}
                        </span>
                        <span style={{ display: 'block', margin: '10px 0px' }}>
                          {splitedVal[1]} {splitedVal[2]}
                        </span>
                      </>
                    );
                  },
                },
                {
                  title: 'Account Details',
                  dataSelector: 'accountsName',
                  dataType: 'string',
                  CellRenderer: (value, item) => (
                    <>
                      {value}
                      <StyledSpan>{item.accountsMobileNumber}</StyledSpan>
                      <StyledSpan>{item.accountsRoleName}</StyledSpan>
                    </>
                  ),
                },
                {
                  title: 'Recharge Details',
                  dataSelector: 'rechargeNumber',
                  dataType: 'string',
                  CellRenderer: (value, item) => (
                    <>
                      {value}
                      {item.operatorName && (
                        <StyledSpan>{item.operatorName}</StyledSpan>
                      )}
                      {item.rechargeCircle &&
                        item.rechargeCircle !== 'null' && (
                          <StyledSpan>{item.rechargeCircle}</StyledSpan>
                        )}
                    </>
                  ),
                },
                {
                  title: 'Transaction Status',
                  dataSelector: 'processedStatus',
                  dataType: 'string',
                  align: 'center',
                  CellRenderer: (value) =>
                    value && (
                      <StatusHighliter
                        className={
                          value === 'Success'
                            ? 'green'
                            : value === 'Failure'
                            ? 'red'
                            : value === 'Pending'
                            ? 'yellow'
                            : 'blue'
                        }
                      >
                        {value}
                      </StatusHighliter>
                    ),
                },
                {
                  title: 'Operator ID',
                  dataSelector: 'apiOperatorsId',
                  dataType: 'string',
                  align: 'center',
                  CellRenderer: (value) => (value ? value : 'N/A'),
                },
                {
                  title: 'API Provider',
                  dataSelector: 'apiProvidersName',
                  dataType: 'string',
                },
                {
                  title: 'Amount',
                  dataSelector: 'amount',
                  dataType: 'number',
                  CellRenderer: (value, item) => (
                    <>
                      RC Amount : {formatCurrency(value)}
                      <StyledSpan>
                        Debited Amount : {formatCurrency(item.actualAmount)}
                      </StyledSpan>
                      <StyledSpan>
                        Commitiion :{formatCurrency(item.rechargeCommission)}
                      </StyledSpan>
                    </>
                  ),
                },
                {
                  title: 'Supervisor 1',
                  dataSelector: 'supervisorsCommission1',
                  dataType: 'number',
                  CellRenderer: (value, item) => (
                    <>
                      Commission : {formatCurrency(value)}
                      {item.supervisorsName1 &&
                        item.supervisorsName1 !== 'null' && (
                          <StyledSpan>{item.supervisorsName1}</StyledSpan>
                        )}
                    </>
                  ),
                },
                {
                  title: 'Supervisor 2',
                  dataSelector: 'supervisorsCommission2',
                  dataType: 'number',
                  CellRenderer: (value, item) => (
                    <>
                      Commission : {formatCurrency(value)}
                      {item.supervisorsName2 &&
                        item.supervisorsName2 !== 'null' && (
                          <StyledSpan>{item.supervisorsName2}</StyledSpan>
                        )}
                    </>
                  ),
                },
                {
                  title: 'API Transaction ID',
                  dataSelector: 'apiTransactionsId',
                  dataType: 'string',
                  align: 'center',
                  CellRenderer: (value) => (value ? value : 'N/A'),
                },
                {
                  title: 'API Users Transaction ID',
                  dataSelector: 'apiUsersTransactionsId',
                  dataType: 'string',
                  align: 'center',
                  CellRenderer: (value) => (value ? value : 'N/A'),
                },
              ]}
              tableData={transactions}
            />
          )}
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
          <Modal
            modalStatus={showModal}
            setModalStatus={() => {
              resetResponseData();
              resetModalForm();
              setShowModal(false);
              setModalType('');
              setLogs([]);
              setReprocessApiProvidersId('');
            }}
            hideCloseButton
          >
            <StyledModalContainer
              width={modalType === 'API_LOGS' ? `1200px` : `500px`}
            >
              <h3>
                {modalType === 'API_LOGS'
                  ? `API Call Logs`
                  : modalType === 'REPROCESS'
                  ? `Reprocess Pending Transactions`
                  : `Update Transaction`}
              </h3>
              {modalType === 'API_LOGS' ? (
                <StyledApiCallLogsContainer>
                  <LoadingSpinner
                    isLoading={
                      responseStatus === '' && logs?.length === 0 && isLoading
                    }
                  />
                  <NoData
                    status={
                      responseStatus !== API_RESPONSE_TYPES.FAILURE &&
                      !isLoading &&
                      logs?.length === 0
                    }
                    message={`No api logs found`}
                  />
                  <MessageBox
                    status={responseStatus}
                    message={responseMessage}
                  />
                  {logs?.length > 0 &&
                    logs.map(
                      (
                        {
                          createdOn,
                          processType,
                          request,
                          requestBody,
                          response,
                          responseType,
                          responseTime,
                        },
                        index
                      ) => (
                        <Accordination
                          key={index}
                          header={`${processType}`}
                          autoExpanded={true}
                        >
                          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
                            <GridItem>
                              <StyledBodyContainer>
                                <StyledHeading>
                                  API Request {createdOn && `( ${createdOn} )`}
                                </StyledHeading>
                                <StyledContent>{request}</StyledContent>
                              </StyledBodyContainer>
                              {requestBody && (
                                <StyledBodyContainer>
                                  <StyledHeading>
                                    API Request Body
                                  </StyledHeading>
                                  <StyledContent>{requestBody}</StyledContent>
                                </StyledBodyContainer>
                              )}
                            </GridItem>
                            <GridItem>
                              <StyledBodyContainer>
                                <StyledHeading>
                                  API Response{' '}
                                  {responseTime && `( ${responseTime} )`}
                                </StyledHeading>
                                <StyledContent>
                                  {responseType === 'XML' ? (
                                    <code>{response}</code>
                                  ) : (
                                    <JSONPretty
                                      id="json-pretty"
                                      data={response}
                                    ></JSONPretty>
                                  )}
                                </StyledContent>
                              </StyledBodyContainer>
                            </GridItem>
                          </Grid>
                        </Accordination>
                      )
                    )}
                </StyledApiCallLogsContainer>
              ) : modalType === 'REPROCESS' ? (
                <FormContainer>
                  <Form method="POST" action="#" onSubmit={onClickReprocess}>
                    <Fieldset>
                      <Label required>API Provider</Label>
                      <Dropdown
                        style={{ border: '1px solid #cacaca' }}
                        placeholder="API Provider"
                        value={reprocessApiProvidersId}
                        onChange={setReprocessApiProvidersId}
                        options={apiProvidersOptions}
                        disabled={isLoading}
                      />
                    </Fieldset>
                    {showModal && responseStatus !== '' && (
                      <Fieldset>
                        <MessageBox
                          status={responseStatus}
                          message={responseMessage}
                        />
                      </Fieldset>
                    )}
                    <Fieldset>
                      <UpdateButton
                        disabled={isLoading === true}
                        type="submit"
                        style={{ width: 'auto' }}
                      >
                        Submit
                      </UpdateButton>
                      <ResetButton
                        disabled={isLoading === true}
                        type="button"
                        onClick={resetModalForm}
                      >
                        Reset
                      </ResetButton>
                    </Fieldset>
                    <OverlayLoader showLoader={showModal && isLoading} />
                  </Form>
                </FormContainer>
              ) : (
                <FormContainer>
                  <Form method="POST" action="#" onSubmit={updateTransaction}>
                    <Fieldset>
                      <Label required>API Transactions ID</Label>
                      <TextInput
                        value={apiTransactionsId}
                        onChange={setApiTransactionsId}
                        placeholder="Please enter api transactions id"
                        disabled={isLoading === true}
                      />
                    </Fieldset>
                    <Fieldset>
                      <Label required>API Operators ID</Label>
                      <TextInput
                        value={apiOperatorsId}
                        onChange={setApiOperatorsId}
                        placeholder="Please enter api operators id"
                        disabled={isLoading === true}
                      />
                    </Fieldset>
                    {showModal && responseStatus !== '' && (
                      <Fieldset>
                        <MessageBox
                          status={responseStatus}
                          message={responseMessage}
                        />
                      </Fieldset>
                    )}
                    <Fieldset>
                      <UpdateButton
                        disabled={isLoading === true}
                        type="submit"
                        style={{ width: 'auto' }}
                      >
                        Update
                      </UpdateButton>
                      <ResetButton
                        disabled={isLoading === true}
                        type="button"
                        onClick={resetModalForm}
                      >
                        Reset
                      </ResetButton>
                    </Fieldset>
                    <OverlayLoader showLoader={showModal && isLoading} />
                  </Form>
                </FormContainer>
              )}
            </StyledModalContainer>
          </Modal>
        </>
      )}
    </>
  );
};

export default withAppHOC(PendingTransactionsTable);
