import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  MessageBox,
  UpdateButton,
  ResetButton,
  NumberInput,
  Dropdown,
} from '../../../components/FormElements';
import { Image } from '../../../components/Image';
import {
  StyledIconTitleContainer,
  StyledIconContainer,
  StyledTitleContainer,
  StyledSpan,
} from '../../../components/Styled';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import { formatCurrency, getReturnUrl } from '../../../utils';

const StyledModuleContainer = styled.section`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #e5e5e5;
  border-bottom: 0px;
  margin: 0px;
  padding: 0px;
  &:last-child {
    border-bottom: 1px solid #e5e5e5;
  }
  @media (max-width: 1200px) {
    padding: 10px;
    display: block;
    width: calc(100% - 22px);
    label {
      margin: 10px 0px;
    }
  }
`;

const StyledModuleNameContainer = styled.section`
  width: auto;
  min-width: 25%;
  padding: 0px 10px;
  @media (max-width: 1200px) {
    padding: 0px;
    width: 100%;
  }
`;

const StyledModuleCommissionContainer = styled.section`
  width: 100%;
  padding: 10px;
  height: auto;
  display: flex;
  flex-direction: row;
  border-left: 1px solid #e5e5e5;
  @media (max-width: 1200px) {
    padding: 10px 0px;
    width: 100%;
    border: 0px;
    display: block;
  }
`;

const MarginTemplatesUpdate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [operators, setOperators] = useState([]);
  const [templateName, setTemplateName] = useState('');
  const [template, setTemplate] = useState([]);
  const [originalFormData, setOriginalFormData] = useState({});
  const { id } = useParams();

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    const { templateName, template } = originalFormData;
    setTemplateName(templateName);
    setTemplate(template);
  };

  const generateTemplate = useCallback(
    (templateName, templateData) => {
      const processedTemplateData =
        typeof templateData === 'string'
          ? JSON.parse(templateData.replace(/\t/g, ''))
          : templateData;

      const newTemplate = operators?.map(
        ({
          id,
          serviceName,
          operatorName,
          operatorLogo,
          surcharge,
          isRange = 'N',
          fromAmount,
          toAmount,
          rangesId,
        }) => {
          // const { commissionType, commission } =
          //   processedTemplateData?.find((item) => item?.operatorsId === id) ||
          //   {};

          const item =
            processedTemplateData?.length > 0 &&
            processedTemplateData?.find((item) =>
              isRange === 'Y'
                ? item?.rangesId === rangesId
                : parseInt(item?.operatorsId) === id
            );

          const commissionType = item?.commissionType ?? 'Percentage';
          const commission = item?.commission ?? 0;
          return {
            operatorsId: id,
            serviceName,
            operatorName,
            operatorLogo,
            commission,
            commissionType,
            surcharge,
            fromAmount,
            toAmount,
            rangesId,
            isRange,
          };
        }
      );

      setTemplateName(templateName);
      setTemplate(newTemplate);
      setOriginalFormData({
        templateName,
        template: newTemplate,
      });
    },
    [operators]
  );

  const readOperators = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get('/recharge-configurations/operators/?isMargin=Y')
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setOperators(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      });
  }, []);

  const readData = useCallback(() => {
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/margins/margin-templates/${id}`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const { templateName, template } = data[0];
          generateTemplate(templateName, template);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      });
  }, [id, generateTemplate]);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      templateName,
      template: JSON.stringify(
        template.map((item) => {
          const newItem = { ...item };
          delete newItem.operatorLogo;

          return newItem;
        })
      ),
    };
    API.put(`/margins/margin-templates/${id}`, formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOriginalFormData(formData);
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.UPDATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    readOperators();
  }, [readOperators]);

  useEffect(() => {
    readData();
  }, [operators, readData]);

  useEffect(() => {
    if (template?.length > 0) {
      setIsLoading(false);
    }
  }, [template]);

  const onChange = (operatorsId, rangesId, commission, isRange = 'N') => {
    const newTemplate = [...template];
    if (isRange === 'Y') {
      const index = newTemplate.findIndex((item) => item.rangesId === rangesId);
      if (index !== -1) {
        const currentValue = newTemplate[index];
        newTemplate.splice(index, 1, { ...currentValue, commission });
      }
    } else {
      const index = newTemplate.findIndex(
        (item) => parseInt(item.operatorsId) === parseInt(operatorsId)
      );
      if (index !== -1) {
        const currentValue = newTemplate[index];
        newTemplate.splice(index, 1, { ...currentValue, commission });
      }
    }
    setTemplate(newTemplate);
  };

  const onChangeCommissionType = (
    operatorsId,
    rangesId,
    commissionType,
    isRange = 'N'
  ) => {
    const newTemplate = [...template];
    if (isRange === 'Y') {
      const index = newTemplate.findIndex((item) => item.rangesId === rangesId);
      if (index !== -1) {
        const currentValue = newTemplate[index];
        newTemplate.splice(index, 1, { ...currentValue, commissionType });
      }
    } else {
      const index = newTemplate.findIndex(
        (item) => parseInt(item.operatorsId) === parseInt(operatorsId)
      );
      if (index !== -1) {
        const currentValue = newTemplate[index];
        newTemplate.splice(index, 1, { ...currentValue, commissionType });
      }
    }
    setTemplate(newTemplate);
  };

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method="POST" action="#" onSubmit={onSubmit}>
          <Fieldset>
            <Label required>Template Name</Label>
            <TextInput
              value={templateName}
              onChange={setTemplateName}
              placeholder="Please enter template name"
              maxLength={100}
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            {template &&
              template.map(
                (
                  {
                    operatorsId,
                    serviceName,
                    operatorLogo,
                    operatorName,
                    commission,
                    commissionType,
                    surcharge,
                    isRange = 'N',
                    fromAmount,
                    toAmount,
                    rangesId,
                  },
                  index
                ) => {
                  return (
                    surcharge === 0 && (
                      <StyledModuleContainer key={index}>
                        <StyledModuleNameContainer>
                          <StyledIconTitleContainer>
                            <StyledIconContainer>
                              <Image
                                source={
                                  operatorLogo ? operatorLogo : '/no-image.png'
                                }
                                alt={operatorName}
                                style={{ width: '50px', height: '50px' }}
                              />
                            </StyledIconContainer>
                            <StyledTitleContainer>
                              {operatorName}
                              <StyledSpan>{serviceName}</StyledSpan>
                            </StyledTitleContainer>
                          </StyledIconTitleContainer>
                        </StyledModuleNameContainer>
                        <StyledModuleNameContainer>
                          <Label>Amount Range</Label>
                          <StyledSpan>
                            {formatCurrency(fromAmount)} -{' '}
                            {formatCurrency(toAmount)}
                          </StyledSpan>
                        </StyledModuleNameContainer>
                        <StyledModuleCommissionContainer>
                          <Fieldset style={{ margin: '0px 10px 0px 0px' }}>
                            <Label required>Commission</Label>
                            <NumberInput
                              value={commission}
                              onChange={(commission) =>
                                onChange(
                                  operatorsId,
                                  rangesId,
                                  commission,
                                  isRange
                                )
                              }
                              placeholder="Please enter commission"
                              maxLength={5}
                              disabled={isLoading === true}
                              pattern={`[0-9.]*`}
                            />
                          </Fieldset>
                          <Fieldset style={{ margin: '0px ' }}>
                            <Label required>Commission Type</Label>
                            <Dropdown
                              placeholder="Please select commission type"
                              value={commissionType}
                              onChange={(commissionType) =>
                                onChangeCommissionType(
                                  operatorsId,
                                  rangesId,
                                  commissionType,
                                  isRange
                                )
                              }
                              options={[
                                {
                                  title: '-- SELECT COMMISSION TYPE --',
                                  value: '',
                                },
                                {
                                  title: 'Percentage',
                                  value: 'Percentage',
                                },
                                {
                                  title: 'Flat',
                                  value: 'Flat',
                                },
                              ]}
                              disabled={isLoading}
                            />
                          </Fieldset>
                        </StyledModuleCommissionContainer>
                      </StyledModuleContainer>
                    )
                  );
                }
              )}
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <UpdateButton disabled={isLoading === true} type="submit">
              Update
            </UpdateButton>
            <ResetButton
              disabled={isLoading === true}
              type="button"
              onClick={resetForm}
            >
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(MarginTemplatesUpdate);
